import React from 'react';

const isBrowser = typeof window !== "undefined"

const useIsMobile = () => {
    const [width, setWidth] = React.useState(isBrowser ? window.innerWidth : null);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    return width <= 768;
};

export default useIsMobile;

export const useIsTablet = () => {
    const [width, setWidth] = React.useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    return width <= 1024;
};