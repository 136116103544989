import React, { useEffect, useRef, useState } from "react";

const useSpecialScrollPosition = (steps) => {
    const [scrollY, setScrollY] = useState(0);
    const [vh, setVh] = useState(0);
    const ref = useRef();

    function logit() {
        setScrollY(window.pageYOffset);
        setVh(Math.round(window.innerHeight));
    }


    const setHeight = () => {
        const main = ref.current;
        if (main) {
            main.style.height = `calc(100vh * ${steps.length})`
        }
    }


    useEffect(() => {
        const main = ref.current;
        const el = main.querySelector('.swiper-container-x')
        const imageSelector = {};
        const itemSelector = {};
        const itemScales = {};


        function watchScroll() {
            let mScale = main.offsetTop - Math.round(scrollY);
            if (main && el) {
                // for (let index = 0; index < steps.length; index++) {
                //     const selector = el.querySelector(`.step-image-${index + 1}`);
                //     if (selector) {
                //         const max = (selector.getBoundingClientRect().height * (steps.length - 1))
                //         let useScale = 0
                //         if (mScale >= 0) {
                //             useScale = 0;
                //         } else if (mScale <= -max) {
                //             useScale = -max;
                //         } else { useScale = mScale }

                //         imageSelector.index = selector;
                //         selector.style.transform = `translate(0, ${useScale}px)`;
                //     }
                // }

                for (let index = 0; index < steps.length; index++) {
                    const selector = el.querySelector(`.step-item-${index + 1}`);
                    itemSelector[index] = selector

                    const iSelector = el.querySelector(`.step-image-${index + 1}`);
                    let scale = 0
                    // if (iSelector) {
                    //     const a = mScale;
                    //     const b = (iSelector.getBoundingClientRect().height * (index + 1));
                    //     const h = iSelector.getBoundingClientRect().height;
                    //     scale = Math.round((1 - ((b + a) / h)) * 100);
                    // } else {
                        const a = mScale;
                        const b = (vh * (index + 1));
                        const h = vh;
                        scale = Math.round((1 - ((b + a) / h)) * 100);
                    // }

                    if (scale <= 0) {
                        itemScales[index] = 0;
                    } else if (scale >= 100) {
                        itemScales[index] = 100;
                    } else { itemScales[index] = scale; }
                    if (iSelector) {
                        if (scale >= 0 && scale < 100) { iSelector.classList.add('active') } else { iSelector.classList.remove('active') }
                    }
                    const track = selector?.querySelector(".timeline-fill");
                    if (index > 0 && itemScales[index - 1] < 100) { track.style.height = '0%' } else { track.style.height = `${itemScales[index]}%`; }
                    if (itemScales[index] > 0 && itemScales[index] < 100) { selector.classList.add("active"); }
                    if ((index !== 0 && itemScales[index] === 0) || (index !== steps.length - 1 && itemScales[index] === 100)) { selector.classList.remove("active"); }

                    setHeight();

                }
            }
            window.addEventListener("scroll", logit);
        }
        watchScroll();
        return () => {
            window.removeEventListener("scroll", logit);
        };
    });
    return ref;
}

export default useSpecialScrollPosition