import React, {Fragment, useEffect} from "react";
import styled from "styled-components";

// import required modules
import {Link, Trans, useTranslation} from "gatsby-plugin-react-i18next";
import {StepIcon} from "../images/nigeria/Icons";
import useIsMobile from "../hooks/useMobile"

const SpecialSwiperComponent = ({steps}) => {
    const isMobile = useIsMobile()
    return (
        <SwiperContainer className="swiper-container-x">
            {!isMobile &&
                <div className="image-container">
                    <div className="inner-container">
                        {steps.map((step, index) => {
                            return <Fragment key={index}>{step.icon}</Fragment>;
                        })}
                    </div>
                </div>
            }
            <div className="steps-container">
                {steps &&
                    steps?.map((step, index) => {
                        return (
                            <StepContainerComponent
                                key={index}
                                className={`${index === 0 && "active"} ${index === steps.length - 1 && "last"
                                } step-item-${index + 1}`}
                            >
                                <div className="timeline-item">
                                    <div className="step-icon">
                                        <div className="step-icon-inner"></div>
                                    </div>
                                    <div className="timeline-path">
                                        <div className={`timeline-fill`}></div>
                                    </div>
                                </div>
                                <div className={`text-section `}>
                                    <p className="step-tag">{step.name}</p>
                                    <h3 className="step-title">{step.title}</h3>
                                    <p className="step-description">{step.description}</p>
                                    {step.action && (
                                        <div className={'d-flex flex-column flex-md-row gap-2 gap-md-4'}>
                                            {
                                                <div className={'w-fit'}>
                                                    {step.action?.options ?
                                                        <a
                                                            href={step.action.link}
                                                            {...step.action.options}
                                                            className={`${step.action?.isBtnSecondary ? 'action-secondary' : 'btn btn-primary'} step-action`}
                                                        >
                                                            {step.action.text}
                                                        </a> :
                                                        <Link
                                                            to={step.action.link}
                                                            className={`${step.action?.isBtnSecondary ? 'action-secondary' : 'btn btn-primary'} step-action`}
                                                        >{step.action.text}
                                                        </Link>
                                                    }
                                                </div>

                                            }
                                                {step?.secondAction &&
                                                <div className={'w-fit'}>
                                                    <Link
                                                        to={step.secondAction.link}
                                                        className={`${step.secondAction?.isBtnSecondary ? 'action-secondary' : 'btn btn-primary'} step-action`}
                                                    >{step.secondAction.text}
                                                    </Link>
                                                </div>
                                            }

                                        </div>
                                    )}
                                </div>
                            </StepContainerComponent>
                        );
                    })}
            </div>
        </SwiperContainer>
    );
};

export default SpecialSwiperComponent;

const SwiperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  align-items: center;


  .image-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;

    .inner-container {
      width: 100%;
      aspect-ratio: 1;
      overflow: hidden;
    }

    .step-image {
      width: 100%;
      max-width: 472px;
      opacity: 0;
      left: 0;
      position: absolute;
      transition: all 0.3s ease-in-out;

      &.active {
        opacity: 1;
      }

      @media only screen and (max-width: 768px) {
        img {
          height: 265.5px;
          width: auto;
          padding: 0;
        }
      }

      img {
        padding: 10px;
      }
    }
  }

  .steps-container {
    width: 100%;
  }


  @media only screen and (max-width: 768px) {
    gap: 48px;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;

    .image-container {
      max-height: 265.5px;
      max-width: 265.5px;
    }
  }


`;

const StepContainerComponent = styled.div`
  display: flex;
  gap: 32px;
  transition: all 0.3s ease;

  &.active .text-section {
    .step-description,
    .step-action {
      position: relative;
      opacity: 1;
      transition: all 0.4s ease-in-out;

    }
  }

  &.last .text-section {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.last .timeline-item {
    .timeline-path {
      display: none;
    }
  }

  &.active .timeline-item {
    .step-icon {
      height: 25px;
      width: 25px;
      border-radius: 25px;

      .step-icon-inner {
        height: 17px;
        width: 17px;
        border-radius: 17px;
        background: rgba(240, 137, 34, 1);
      }
    }

    .timeline-fill {
      border-radius: 0 0 5px 5px;
    }
  }

  .timeline-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25px;
    position: relative;

    .step-icon {
      height: 20px;
      width: 20px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 4px 4px 0px rgba(0, 68, 190, 0.15);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      position: absolute;
      transition: all 0.3s ease;

      .step-icon-inner {
        height: 12px;
        width: 12px;
        border-radius: 12px;
        background: #0361f0;
      }
    }

    .timeline-path {
      margin-top: 1px;
      margin-bottom: -10px;
      height: 100%;
      width: 9px;
      background-color: rgba(11, 50, 117, 0.1);
    }

    .timeline-fill {
      background-color: rgba(3, 97, 240, 1);
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    max-width: 498px;
    width: 100%;

    border-bottom: 0.7px solid rgba(3, 87, 238, 0.59);
    margin-bottom: 28px;
    padding-bottom: 28px;

    .step-tag {
      width: fit-content;
      padding: 3px 8px;
      background: rgba(3, 87, 238, 0.1);
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
      color: #0361f0;
      text-transform: uppercase;
      border-radius: 4px;
      margin-bottom: 8px;
    }

    .step-title {
      font-family: "Founders Grotesk";
      font-size: 32px;
      font-weight: 500;
      line-height: 35px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 0px;
      color: rgba(26, 12, 47, 1);
    }

    .step-description {
      margin-top: 17px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0;
    }

    .step-action {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      padding: 14.5px 24px;
      border-radius: 4px;
      margin-top: 16px;
    }

    .step-description,
    .step-action {
      position: absolute;
      opacity: 0;
      transition: position 0.1s ease;

    }

    .action-secondary {
      display: flex;
      height: 48px;
      padding: 7px 24px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      text-decoration: none;
      border: 1px solid var(--colors-primary-blue-100, #0361F0);
      background: var(--colors-primary-blue-10, #E6EEFD);
    }
    .w-fit{
      width: fit-content;
    }
  }

  @media only screen and (max-width: 768px) {
    .timeline-item {

      .step-icon {
        height: 20px;
        width: 20px;
        border-radius: 20px;
      }

      .timeline-path {
        width: 6px;
      }
    }


    .text-section {
      .step-title {
        font-size: 24px;
        line-height: 26.4px;
        margin-bottom: 0px;
      }

      .step-description {
        font-size: 16px;
        line-height: 24px;
        margin: 8px 0 0;
      }

      .step-action {
        padding: 7px 24px;
        margin-top: 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;

      }
    }
  }
`;
