import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet, Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import styled from "styled-components";
import GetStartedComponent from "../../../components/GetStartedComponent";
import SpecialSwiperComponent from "../../../components/SpecialSwiperComponent";
import Layout from "../../../components/nigeria/layout";
import { Nigeria_MetaTags } from "../../../helpers/constants";
import useSpecialScrollPosition from "../../../hooks/useSpecialScrollPosition";
import {
  AcceptIcon,
  ArrowRightShortIcon,
  CardIcon,
  CollapseMenuIcon,
  EasyOsIcon,
  ExpandMenuIcon,
  InstantIcon,
  LockIcon,
  SupportIcon,
} from "../../../images/nigeria/Icons";
import HeroImage from "../../../images/nigeria/pos-terminals/hero.png";
import PosVideo from "../../../images/nigeria/pos-terminals/pos.mp4";
import StatsBg from "../../../images/nigeria/pos-terminals/stats-bg.gif";

const PosTerminal = () => {
  const { t } = useTranslation();
  const steps = [
    {
      name: t("step_one"),
      title: t("pos_page.open_step_title_1"),
      description: t("pos_page.open_step_description_1"),
      action: {
        text: t("open_your_account"),
        link: "https://moniepoint.go.link?adj_t=yb9ys89&adj_fallback=https%3A%2F%2Fatm.moniepoint.com%2Fonboarding-v2",
        option: { target: "_blank" },
      },
      icon: (
        <StaticImage
          className="step-image step-image-1"
          src="../../../images/nigeria/pos-terminals/step-1.png"
          alt="POS Terminal"
        />
      ),
    },
    {
      name: t("step_two"),
      title: t("pos_page.open_step_title_2"),
      description: t("pos_page.open_step_description_2"),
      icon: (
        <StaticImage
          className="step-image step-image-2"
          src="../../../images/nigeria/pos-terminals/step-2.png"
          alt="POS Terminal"
        />
      ),
    },
    {
      name: t("step_three"),
      title: t("pos_page.open_step_title_3"),
      description: t("pos_page.open_step_description_3"),
      icon: (
        <StaticImage
          className="step-image step-image-3"
          src="../../../images/nigeria/pos-terminals/step-3.png"
          alt="POS Terminal"
        />
      ),
    },
    {
      name: t("step_four"),
      title: t("pos_page.open_step_title_4"),
      description: t("pos_page.open_step_description_4"),
      icon: (
        <StaticImage
          className="step-image step-image-4"
          src="../../../images/nigeria/pos-terminals/step-4.png"
          alt="POS Terminal"
        />
      ),
    },
  ];
  const ref = useSpecialScrollPosition(steps);

  return (
    <Layout
      pageTitle={Nigeria_MetaTags.pos.title}
      description={Nigeria_MetaTags.pos.description}
    >
      <Helmet>
        <link rel="canonical" href="https://moniepoint.com/ng/payment/pos-terminals" />
        <link rel="alternate" href="https://moniepoint.com/ng/payment/pos-terminals" hreflang="x-default" />
        <link rel="alternate" href="https://moniepoint.com/ng/payment/pos-terminals" hreflang="en-ng" />
        <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"How much does the Moniepoint POS terminal cost?","acceptedAnswer":{"@type":"Answer","text":"The Moniepoint POS terminal costs N21,500 only."}},{"@type":"Question","name":"How long does it take to get a Moniepoint POS terminal?","acceptedAnswer":{"@type":"Answer","text":"After you make a request, you can get the Moniepoint POS terminal within 48hrs."}},{"@type":"Question","name":"What cards can I accept on my Moniepoint POS terminal?","acceptedAnswer":{"@type":"Answer","text":"Your POS terminal is compatible with all cards."}},{"@type":"Question","name":"Can I accept transfers with my Moniepoint POS terminal?","acceptedAnswer":{"@type":"Answer","text":"Yes, you can."}},{"@type":"Question","name":"Do I need special training to use the Moniepoint POS terminal?","acceptedAnswer":{"@type":"Answer","text":"No, but you can always contact our customer support if you have any questions."}},{"@type":"Question","name":"Can I get more than one Moniepoint POS terminal for my business?","acceptedAnswer":{"@type":"Answer","text":"Yes, we provide multiple terminals for large businesses."}},{"@type":"Question","name":"Who can I ask questions about my Moniepoint POS terminal?","acceptedAnswer":{"@type":"Answer","text":"Our wonderful customer support team members are always there for you. Call  01 888 9990 if you have a question."}}]}`}</script>
      </Helmet>
      <HeroContainer src={HeroImage}>
        <div className="hero-mask"></div>
        <div className="hero-content-wrapper">
          <div className="hero-content">
            <h1 className="hero-title font-grotesk">
              <Trans>pos_page.hero_title</Trans>
            </h1>
            <p className="hero-subtitle">
              <Trans>pos_page.hero_subtitle</Trans>
            </p>
            <a
              href="https://airtable.com/app8MFYFOKihXpB7L/shrQEhOHoAerUBzru"
              target="_blank"
              className="btn btn-primary"
            >
              <Trans>join_waitlist</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>
        </div>
      </HeroContainer>
      <StatsContainer>
        <div className="section-video"></div>
        <div className="section-mask"></div>
        <div className="content">
          <div className="stats-section">
            <div className="stats-item">
              <StaticImage
                className="stat-image"
                src="../../../images/nigeria/pos-terminals/success-rate.png"
                alt="stat_title_1"
              />
              <div className="stat-body">
                <h3 className="title">99%</h3>
                <p className="description">
                  <Trans>pos_page.stat_title_1</Trans>
                </p>
              </div>
            </div>
            <div className="stats-divider"></div>
            <div className="stats-item">
              <StaticImage
                className="stat-image"
                src="../../../images/nigeria/pos-terminals/businesses-trust-us.png"
                alt="stat_title_2"
              />
              <div className="stat-body">
                <h3 className="title">600k+</h3>
                <p className="description">
                  <Trans>pos_page.stat_title_2</Trans>
                </p>
              </div>
            </div>
            <div className="stats-divider"></div>
            <div className="stats-item">
              <StaticImage
                className="stat-image"
                src="../../../images/nigeria/pos-terminals/processed-monthly.png"
                alt="stat_title_3"
              />
              <div className="stat-body">
                <h3 className="title">7 trillion</h3>
                <p className="description">
                  <Trans>pos_page.stat_title_3</Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </StatsContainer>
      <HowToContainer ref={ref} className="swiper-main-x">
        <div className="sticky-container">
          <h3 className="title">
            <Trans>pos_page.how_to_title</Trans>
          </h3>
          <SpecialSwiperComponent steps={steps} />
          <a
            href="https://airtable.com/shrR9t7GKBuudRzQ7"
            target="_blank"
            className="btn btn-primary get-pos-button"
          >
            <Trans>join_waitlist</Trans>
            <ArrowRightShortIcon size={24} />
          </a>
        </div>
      </HowToContainer>
      <FeatureContainer>
        <div className="content">
          <div className="text-section">
            <h3 className="title">
              <Trans>pos_page.features_title</Trans>
            </h3>
            <div className="image-section-sm">
              <StaticImage
                className=""
                src="../../../images/nigeria/pos-terminals/feature-image.png"
                alt="POS Terminal"
              />
            </div>
            <div className="features-list">
              <div className="feature-item">
                <LockIcon />
                <span>
                  <Trans>pos_page.feature_item_1</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <EasyOsIcon />
                <span>
                  <Trans>pos_page.feature_item_2</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <SupportIcon />
                <span>
                  <Trans>pos_page.feature_item_3</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <CardIcon />
                <span>
                  <Trans>pos_page.feature_item_4</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <InstantIcon />
                <span>
                  <Trans>pos_page.feature_item_5</Trans>
                </span>
              </div>
              <div className="feature-divider"></div>
              <div className="feature-item">
                <AcceptIcon />
                <span>
                  <Trans>pos_page.feature_item_6</Trans>
                </span>
              </div>
            </div>
            <a
              href="https://airtable.com/shrR9t7GKBuudRzQ7"
              target="_blank"
              className="btn btn-primary"
            >
              <Trans>join_waitlist</Trans>
              <ArrowRightShortIcon size={24} />
            </a>
          </div>
          <div className="image-section">
            <StaticImage
              className=""
              src="../../../images/nigeria/pos-terminals/feature-image.png"
              alt="POS Terminal"
            />
          </div>
        </div>
      </FeatureContainer>
      <AcceptTransferContainer>
        <div className="content">
          <h3 className="title">
            <Trans>pos_page.accept_transfer_title</Trans>
          </h3>
          <p className="description">
            <Trans>pos_page.accept_transfer_description</Trans>
          </p>
          <Link
            to="/blog/receive-transfer-payments-with-your-pos"
            target="_blank"
            className="btn btn-primary accept-transfer-button"
          >
            <Trans>pos_page.accept_transfer_cta</Trans>
            <ArrowRightShortIcon size={24} />
          </Link>
          <div className="image-section">
            <div className="pos-video-container">
              <video
                autoPlay
                loop
                className="pos-video"
                muted
                playsInline
                onContextMenu={(e) => { e.stopPropagation(); }}
                preload="auto"
              >
                <source src={PosVideo} type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
            <StaticImage src="../../../images/nigeria/pos-terminals/accept_pos_transfer.png" alt="..." />
          </div>
        </div>
      </AcceptTransferContainer>
      <LargeImageContainer>
        <div className="content">
          <h3 className="title">
            <Trans>pos_page.in_the_box_title</Trans>
          </h3>
          <div className="image-wrapper">
            <StaticImage
              className="hide-sm"
              src="../../../images/nigeria/pos-terminals/in-the-box.png"
              alt="..."
            />
            <StaticImage
              className="show-sm"
              src="../../../images/nigeria/pos-terminals/in-the-box-sm.png"
              alt="..."
            />
          </div>
        </div>
      </LargeImageContainer>
      <FAQsContainer>
        <div className="content">
          <h3 className="title">
            <Trans>pos_page.faqs_title</Trans>
          </h3>
          <div className="faqs-list">
            <FaqItem
              title="pos_page.faq_1_title"
              description="pos_page.faq_1_description"
            />
            <div className="faq-divider"></div>
            <FaqItem
              title="pos_page.faq_2_title"
              description="pos_page.faq_2_description"
            />
            <div className="faq-divider"></div>
            <FaqItem
              title="pos_page.faq_3_title"
              description="pos_page.faq_3_description"
            />
            <div className="faq-divider"></div>
            <FaqItem
              title="pos_page.faq_4_title"
              description="pos_page.faq_4_description"
            />
            <div className="faq-divider"></div>
            <FaqItem
              title="pos_page.faq_5_title"
              description="pos_page.faq_5_description"
            />
            <div className="faq-divider"></div>
            <FaqItem
              title="pos_page.faq_6_title"
              description="pos_page.faq_6_description"
            />
            <div className="faq-divider"></div>
            <FaqItem
              title="pos_page.faq_7_title"
              description="pos_page.faq_7_description"
            />
          </div>
        </div>
      </FAQsContainer>
      <GetStartedComponent
        title="pos_page.pos_get_started"
        cta="join_waitlist"
        link="https://airtable.com/shrR9t7GKBuudRzQ7"
        option={{ target: "_blank" }}
        bgColor="rgba(3, 87, 238, 1)"
      />
    </Layout>
  );
};

export default PosTerminal;

export const query = graphql`
query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const FaqItem = ({ title, description, open }) => {
  const [isOpen, setIsOpen] = useState(open || false);
  const toggleIsOpen = () => setIsOpen((value) => !value);
  return (
    <FAQItemConatainer>
      <div className="text-section">
        <div className="title" onClick={toggleIsOpen}>
          <Trans>{title}</Trans>
        </div>
        <div className={`description ${isOpen && "open"}`}>
          <Trans>{description}</Trans>
        </div>
      </div>
      <div className="toggle-button" onClick={toggleIsOpen}>
        {isOpen ? <CollapseMenuIcon /> : <ExpandMenuIcon />}
      </div>
    </FAQItemConatainer>
  );
};


const HeroContainer = styled.section`
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 497px;
  max-height: 575px;
  position: relative;

  .hero-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 113px 149px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
  }

  .hero-content {
    color: white;

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 58px;
      font-weight: 500;
      line-height: 66px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 16px;
      max-width: 652px;
    }

    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 476px;
      margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 730px;
    background-size: cover;
    background-position: 70%;

    .hero-mask {
      background: linear-gradient(
        180deg,
        rgba(29, 29, 29, 0) 0%,
        rgba(29, 29, 29, 0.5) 79.14%
      );
    }

    .hero-content-wrapper {
      padding: 47px 20px;
    }

    .hero-content {
      .hero-title {
        font-size: 32px;
        line-height: 35px;
      }
      .hero-subtitle {
        font-size: 17px;
        line-height: 27.37px;
      }
    }
  }
`;

const StatsContainer = styled.section`
  background: #f7fafe;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  position: relative;

  .section-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url(${StatsBg});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.26;
  }

  .section-mask {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      360deg,
      #ffffff 13.58%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
  }

  .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    padding: 286px 20px;

    .stats-section {
      z-index: 2;
      position: relative;
      width: 100%;
      padding: 50px 65px 49px;
      background: #ffffff;
      box-shadow: 0px 26px 47px rgba(0, 41, 114, 0.15);
      border-radius: 16px;
      display: flex;
      gap: 48px;
      align-items: center;
      justify-content: space-between;
      max-width: 999.84px;
      margin: auto;
      padding: 40px 48px;

      .stats-divider {
        height: 96px;
        width: 0.7px;
        background-color: rgba(3, 87, 238, 0.23);
      }

      .stats-item {
        display: flex;
        align-items: center;
        gap: 0px;
        .stat-image {
          max-height: 72px;
          max-width: 72px;
        }
        .title {
          font-family: "Founders Grotesk";
          font-size: 40px;
          font-weight: 500;
          line-height: 47px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 8px;
          color: rgba(0, 0, 0, 0.8);
        }
        .description {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 0px;
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 79px 20px;
      justify-content: flex-start;

      .stats-section {
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto;
        max-width: 335px;
        padding: 22.5px 24px;
        border-radius: 9px;
        gap: 16px;
        /* width: 100%; */

        .stats-divider {
          width: 54px;
          height: 0.39px;
          background-color: rgba(3, 87, 238, 0.23);
        }

        .stats-item {
          .stat-image {
            /* max-height: 30px;
          max-width: 30px; */
          }

          .title {
            font-size: 22.5px;
            line-height: 26.55px;
            margin-bottom: 0px;
          }
          .description {
            font-size: 12px;
            line-height: 14.52px;
          }
        }
      }
    }
  }
`;

const HowToContainer = styled.section`
  background-color: white;
  height: ${(props) => props.height};

  .sticky-container {
    position: sticky;
    top: -1.5em;
    overflow-y: hidden;
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }
    text-align: center;

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 64px;
    }

    .get-pos-button {
      margin: 64px auto 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .sticky-container {
      padding: 48px 20px;
      .title {
        font-size: 28px;
        line-height: 33px;
        margin-bottom: 32px;
      }
    }
  }
`;

const FeatureContainer = styled.section`
  background-color: rgba(3, 87, 238, 0.03);
  .visible-sm {
    display: unset;
  }
  .hidden-sm {
    display: none;
  }

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px;
    padding-right: 60.5px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    .image-section {
      height: auto;
      max-width: 623.55px;
      width: 50%;

      img {
        height: 100%;
      }
    }

    .text-section {
      max-width: 533px;

      .image-section-sm{
        display: none;
        margin-bottom: 48px;
        align-items: center;

        img {
          height: 427px;
        }
      }

      .title {
        font-family: "Founders Grotesk";
        font-size: 40px;
        font-weight: 500;
        line-height: 47px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 638px;
        margin-bottom: 48px;
      }

      .features-list {
        display: flex;
        flex-direction: column;
        margin-bottom: 48px;
        max-width: 444px;

        .feature-item {
          display: flex;
          gap: 12px;
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          align-items: center;

          svg > path {
            fill: rgba(11, 50, 117, 0.5);
          }
        }

        .feature-divider {
          border: 0.8px solid rgba(3, 87, 238);
          background: rgba(3, 87, 238);
          margin: 28px 0;
          position: relative;
          opacity: 0.3;

          &:after {
            position: absolute;
            bottom: -3px;
            left: 0;
            height: 6px;
            width: 6px;
            background:rgba(3, 87, 238);
            content: "";
            border-radius: 3px;
          }

          &:after {
            right: -3px;
            left: auto;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .visible-sm {
      display: none;
    }
    .hidden-sm {
      display: unset;
    }

    .content {
      padding: 48px 20px 124px;
      flex-direction: column;
      align-items: center;

      .image-section {
      display: none;

      }

      .text-section {
        align-items: center;
        width: 100%;

        .image-section-sm{
        display: flex;
        flex-direction: column
      
      }

        .title {
          font-family: "Founders Grotesk";
          font-size: 28px;
          font-weight: 500;
          line-height: 33px;
          letter-spacing: 0em;
          text-align: center;
          max-width: 298px;
          margin: 0 auto 32px;
        }

        .features-list {
          max-width: none;
        }

        .btn.btn-primary{
          margin: 0 auto;
        }

        &.ordered-3{
          order: 3;
          margin-bottom: 0px;
        }
      }
    }
  }
`;

const AcceptTransferContainer = styled.section`
  background-color: white;

  .content {
    position: relative;
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px 0;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px 0;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: center;
      color: rgba(0, 0, 0, 1);
      margin: 0 auto 8px;
    }
    .description {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      max-width: 554px;
      margin: 0 auto 16px;
    }

    .accept-transfer-button {
      margin: 0 auto 52px;
      padding: 18.5px 24px;
      text-transform: capitalize;
    }

    .image-section {
      max-height: 542px;
      max-width: 612px;
      margin: 0 auto;
      overflow: hidden;
      position: relative;

      img {
        /* object-fit: contain; */
        object-position: top;
      }

      .pos-video-container {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        z-index: 10;

        .pos-video {
          margin: auto 28% 13.5% auto;
          max-width: 45%;
          width: 100%;
          border-radius: 4px;
        }
        
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px 0;

      .title {
        font-size: 32px;
        line-height: 37.76px;
        margin: 0 auto 8px;
        max-width: 288px;
      }
      .description {
        font-size: 14px;
        line-height: 22.4px;
        margin: 0 auto 16px;
      }

      .accept-transfer-button {
        margin: 0 auto 61px;
        padding: 12px 24px;
        text-transform: capitalize;
      }

      .image-section {
        max-height: 542px;
        max-width: 612px;
        margin: 0 auto;
        overflow: hidden;

        img {
          object-position: top;
        }
      }
    }
  }
`;

const LargeImageContainer = styled.section`
  background-color: rgba(3, 87, 238, 0.03);
  .content {
    position: relative;
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px 0px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px 0px;
    }
    @media only screen and (max-width: 768px) {
      padding: 47px 20px 42px;

    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: center;
      color: rgba(0, 0, 0, 1);
      @media only screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 38px;
      }
    }

    .image-wrapper {
      .show-sm {
        display: none;

      
      }

      @media only screen and (max-width: 768px) {
        
        .show-sm {
          display: block;
        }
        .hide-sm {
          display: none;
        }
      }
    }
  }
`;

const FAQsContainer = styled.section`
  background-color: white;

  .content {
    position: relative;
    max-width: 1440px;
    margin: auto;
    padding: 64px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 64px 60px;
    }
    .title {
      font-family: "Founders Grotesk";
      font-size: 48px;
      font-weight: 500;
      line-height: 57px;
      letter-spacing: 0em;
      text-align: center;
      max-width: 554px;
      margin: 0 auto 48px;
      color: rgba(0, 0, 0, 1);
    }

    .faqs-list {
      width: 100%;

      .faq-divider {
        width: 100%;
        height: 1px;
        background: rgba(3, 87, 238, 0.1);
        margin: 32px 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .content {
      padding: 48px 20px;

      .title {
        font-size: 48px;
        line-height: 37.76px;
        margin: 0 auto 48px;
        max-width: 288px;
      }

      .faqs-list {
      }
    }
  }
`;

const FAQItemConatainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  .toggle-button {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }

  .text-section {
    .title {
      max-width: 895px;
      font-family: "Founders Grotesk";
      font-size: 28px;
      font-weight: 500;
      line-height: 33px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 0px;
      cursor: pointer;
    }

    .description {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0px;
      display: none;

      &.open {
        display: block;
        margin-top: 16px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    gap: 26px;

    .text-section {
    }
  }
`;
